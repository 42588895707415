// ====== GLOBAL COLORS  ========
@bar-infos: rgba(0, 0, 0, 0.4);
@black-light: #b9b9b9;
@black-transparent-menu: rgba(0, 0, 0, 0.85);
@black-transparent: rgba(0, 0, 0, 0.6);
@black-dark: rgba(0, 0, 0, 1);
@black: #707070;
@blue-dark: #3800d2;
@blue: #51b9fa;
@blue-mana: #006cb2;
@blue-light: #87cdfa;
@green-transparent: rgba(40, 180, 54, 0.7);
@green: #28b436;
@green-light: #1fd22f;
@grey: #d2d2d2;
@orange-difficulty: #ff8400;
@orange: #ff9021;
@orange-light: #ffa03e;
@purple: #a74eff;
@purple-light: #bb73ff;
@red-transparent: rgba(255, 65, 65, 0.7);
@red: #ff4141;
@red-health: #cc0000;
@white-transparent-l: rgba(255, 255, 255, 0.85);
@white-transparent-quest: rgba(255, 255, 255, 0.175);
@white-transparent-slot: rgba(255, 255, 255, 0.2);
@white-transparent: rgba(255, 255, 255, 0.7);
@white: white;
@yellow-lvl: #ffe100;
@yellow-transparent: rgba(230, 172, 18, 0.7);
@yellow: #e6ac12;

// ====== CUSTOMS COLORS  ========
@primary: #9f620e;
@primary-light: #f5efe8;
@primary-transparent: rgba(141, 100, 43, 0.6);
@primary-transparent-plus: rgba(141, 100, 43, 0.3);
@primary-transparent-more: rgba(141, 100, 43, 0.2);
@gmod: #1f93e9;
@discord: #738ad8;

// ====== border ========
@border: 2px solid @black-light;
@btn-border: @primary;

// ====== MARGIN & PADDING ========
@small-margin: 10px auto;
@margin: 25px auto;

@small: 10px;
@medium: 50px;

// ====== Z-INDEX  ========
@z-index-menu-bottom: 60;
@z-index-menu: 70;
@z-index-menu-top: 80;

@map-width: 1088px;
@map-height: 608px;

.primary-color {
  color: @primary;
  fill: @primary;
}
