// ====== RESET CSS  ========

* {
  box-sizing: border-box;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

input {
  -webkit-user-select: text; /* Safari */
  -moz-user-select: text; /* Old versions of Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
  user-select: text;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
p,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button {
  margin: 0;
  padding: 0;
  border: 0;
  .font-size(0.9375rem);
  text-overflow: ellipsis;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  vertical-align: baseline;
  color: @white;

  @media @large-desktop {
    .font-size(1rem);
  }
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100dvh;
  min-height: 100svh;
  overflow: hidden;
}

body {
  touch-action: manipulation;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

*:focus {
  outline: none;
}

// ====== GLOBAL CSS  ========

.uppercase {
  text-transform: uppercase;
}

svg {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

h1 {
  .font-size(1.375rem);
  font-weight: 400;
}

h2 {
  .font-size(1.125rem);
  font-weight: 400;
}

h3 {
  .font-size(1rem);
  font-weight: 400;
}

p {
  .font-size(1rem);
  font-weight: 300;
}

.bold {
  font-weight: 700;
}

.orange {
  color: @primary;
}
